import React from 'react';

import { Animated } from "react-animated-css"
import * as Icon from "react-feather"

const Header = ({ search, text, image, big }) => {
  if (search) {
    return (
      <section className="header" style={{
        backgroundImage: 'url("http://www.photonic.se/wp-content/uploads/2019/06/cropped-iStock-1082937588.jpg")'
      }}>
        <div className="text-holder">
          <Animated animationIn="fadeInDown" isVisible={true} children={
            <h2>Din lokala solcellsleverantör</h2>
          } />
          <Animated animationIn="fadeInDown" isVisible={true} children={
            <p>Boka ett kostnadsfritt hembesök eller sök nedan för att se hur mycket el ditt tak kan producera</p>
          } />
  
          <Animated animationIn="fadeInUp" isVisible={true} children={
            <form onSubmit={''}>
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Skriv din adress här..." />
                <button type="submit" className="input-group-text"><Icon.Search /></button>
              </div>
            </form>
          } />
        </div>
      </section>
    );
  }

  if (image) {
    return (
      <section className={big ? "header image big" : "header image"} style={{
        backgroundImage: 'url("https://solarvolt.se/solceller/images/skillnaden_mellan_olika_takintegrerade_solceller.jpg")',
        height: '420px !important'
      }}>
        {text !== undefined && (
          <div className="container">
            <div className="row">
              <div className="col">
                <h2 style={{
                  color: '#fff',
                  fontSize: '46px',
                  position: 'relative',
                  zIndex: '50000'
                }}>{text}</h2>
              </div>
            </div>
          </div>
        )}
      </section>
    )
  }

  return (
    <section className="header small" style={{
      backgroundImage: 'url("https://solarvolt.se/solceller/images/skillnaden_mellan_olika_takintegrerade_solceller.jpg")'
    }}>
      <div className="container">
        <div className="row">
          <div className="col"><h2>{text}</h2></div>
        </div>
      </div>
    </section>
  )
};

export default Header;